import React from 'react';

const UxUiVisualDesign = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3478 38.9736C19.4187 38.8501 15.5552 37.9285 11.991 36.2645C8.42682 34.6004 5.23634 32.2286 2.61144 29.2923C2.55063 29.2315 2.50236 29.1591 2.46944 29.0795C2.43652 29 2.41968 28.9147 2.41968 28.8285C2.41968 28.7424 2.43652 28.6571 2.46944 28.5775C2.50236 28.498 2.55063 28.4258 2.61144 28.365C8.43951 22.5848 16.1721 19.1417 24.3537 18.6836H24.5707C28.4987 18.8134 32.3603 19.7378 35.9236 21.4014C39.4869 23.065 42.6789 25.4335 45.3078 28.365C45.3728 28.4262 45.4243 28.5001 45.4597 28.5822C45.4951 28.6643 45.5135 28.7527 45.5135 28.8422C45.5135 28.9316 45.4951 29.0201 45.4597 29.1022C45.4243 29.1843 45.3728 29.2582 45.3078 29.3194C39.4826 35.1038 31.7483 38.5478 23.5655 39.0009L23.3478 38.9736ZM4.05194 28.8285C6.53092 31.4986 9.51238 33.6493 12.8244 35.1565C16.1364 36.6638 19.7129 37.4977 23.3478 37.6101H23.5655C31.1452 37.1439 38.3252 34.0382 43.8673 28.8285C41.369 26.1228 38.3542 23.9488 35.0019 22.4356C31.6497 20.9224 28.0287 20.1008 24.3537 20.0199C16.7705 20.4935 9.59008 23.6089 4.05194 28.8285Z"
      fill="#475467"
    />
    <path
      d="M23.9486 38.9736C21.9425 38.9736 19.9817 38.3763 18.314 37.2573C16.6463 36.1383 15.3468 34.548 14.5803 32.6877C13.8139 30.8274 13.615 28.7807 14.0084 26.8068C14.4018 24.8328 15.3701 23.0203 16.7905 21.5988C18.211 20.1773 20.0197 19.2105 21.9879 18.821C23.9562 18.4316 25.9949 18.637 27.8468 19.4111C29.6987 20.1852 31.2806 21.4934 32.3913 23.1698C33.5019 24.8462 34.0913 26.8154 34.0859 28.8285C34.0787 31.5216 33.0077 34.102 31.1074 36.0038C29.207 37.9055 26.6325 38.9736 23.9486 38.9736ZM23.9486 20.0198C22.2124 20.0198 20.5153 20.5365 19.0717 21.5044C17.6281 22.4723 16.5028 23.8481 15.8384 25.4577C15.174 27.0672 15 28.8384 15.3388 30.5471C15.6775 32.2558 16.5137 33.8254 17.7414 35.0573C18.9691 36.2892 20.5332 37.1281 22.2361 37.468C23.9389 37.8079 25.704 37.6334 27.308 36.9667C28.9121 36.3 30.2834 35.1709 31.248 33.7224C32.2126 32.2738 32.727 30.5707 32.727 28.8285C32.727 26.4923 31.8021 24.2518 30.1559 22.5998C28.5096 20.9479 26.2768 20.0198 23.9486 20.0198Z"
      fill="#475467"
    />
    <path
      d="M23.9498 34.1218C22.9145 34.0896 21.9121 33.7522 21.0668 33.1517C20.2214 32.5511 19.5711 31.7141 19.197 30.7449C18.8228 29.7758 18.7413 28.7176 18.9627 27.7024C19.1842 26.6871 19.6983 25.7598 20.4417 25.0361C21.1852 24.3125 22.1249 23.8245 23.1429 23.6333C24.161 23.442 25.2126 23.556 26.1666 23.9608C27.1206 24.3657 27.9344 25.0434 28.5069 25.9095C29.0793 26.7757 29.3849 27.7918 29.3854 28.8311C29.3857 29.5398 29.2444 30.2415 28.9693 30.8943C28.6942 31.5471 28.2913 32.1377 27.7843 32.6312C27.2773 33.1247 26.6766 33.511 26.018 33.7671C25.3594 34.0231 24.6558 34.1437 23.9498 34.1218ZM23.9498 24.8767C23.1704 24.8767 22.4089 25.1086 21.7608 25.5431C21.1128 25.9777 20.6073 26.5953 20.309 27.3179C20.0108 28.0404 19.9327 28.8355 20.0848 29.6026C20.2368 30.3697 20.6125 31.0743 21.1637 31.6273C21.7148 32.1803 22.417 32.557 23.1814 32.7096C23.9459 32.8622 24.7379 32.7838 25.458 32.4845C26.1781 32.1852 26.7939 31.6784 27.2269 31.0281C27.6599 30.3778 27.8911 29.6132 27.8911 28.8311C27.8911 27.7823 27.4756 26.7766 26.7366 26.035C25.9975 25.2934 24.9949 24.8767 23.9498 24.8767Z"
      fill="#475467"
    />
    <path
      d="M27.9987 28.9112C27.8195 28.9015 27.6483 28.8346 27.5097 28.7203C27.3894 28.5853 27.3232 28.4105 27.3232 28.2294C27.3232 28.0483 27.3894 27.8736 27.5097 27.7386C27.5739 27.6741 27.65 27.623 27.734 27.5881C27.8179 27.5532 27.9079 27.5352 27.9987 27.5352C28.0896 27.5352 28.1795 27.5532 28.2635 27.5881C28.3474 27.623 28.4241 27.6741 28.4884 27.7386C28.5526 27.803 28.6036 27.8795 28.6383 27.9638C28.6731 28.048 28.6908 28.1383 28.6908 28.2294C28.6908 28.3206 28.6731 28.4109 28.6383 28.4951C28.6036 28.5793 28.5526 28.6558 28.4884 28.7203C28.3497 28.8346 28.1779 28.9015 27.9987 28.9112Z"
      fill="#475467"
    />
    <path
      d="M23.27 33.6564C23.1386 33.6547 23.011 33.6147 22.9017 33.5414C22.7925 33.4681 22.7067 33.3645 22.6549 33.2433C22.6031 33.122 22.5874 32.9882 22.6098 32.8583C22.6322 32.7283 22.6916 32.6078 22.781 32.511L25.8524 29.4293C25.9833 29.3309 26.1451 29.2831 26.3083 29.2947C26.4714 29.3063 26.6246 29.3765 26.7402 29.4926C26.8559 29.6086 26.9257 29.7627 26.9373 29.9264C26.9489 30.0901 26.9014 30.2525 26.8032 30.3838L23.7325 33.4655C23.6727 33.5275 23.6003 33.5765 23.5208 33.6093C23.4413 33.6421 23.3559 33.6582 23.27 33.6564Z"
      fill="#475467"
    />
    <path
      d="M21.0407 32.4326C20.8643 32.4229 20.6981 32.3446 20.5783 32.2144C20.5133 32.1532 20.4617 32.0792 20.4263 31.9972C20.3909 31.9151 20.3726 31.8266 20.3726 31.7372C20.3726 31.6477 20.3909 31.5592 20.4263 31.4772C20.4617 31.3951 20.5133 31.3211 20.5783 31.2599L23.296 28.5328C23.3594 28.4668 23.4356 28.4143 23.5196 28.3785C23.6037 28.3426 23.6944 28.3242 23.7857 28.3242C23.877 28.3242 23.9671 28.3426 24.0511 28.3785C24.1352 28.4143 24.2114 28.4668 24.2747 28.5328C24.3931 28.6633 24.4585 28.8335 24.4585 29.01C24.4585 29.1865 24.3931 29.3567 24.2747 29.4872L21.5569 32.2144C21.4913 32.2855 21.4111 32.3419 21.3221 32.3795C21.233 32.4171 21.1373 32.4352 21.0407 32.4326Z"
      fill="#475467"
    />
    <path
      d="M19.8237 30.2255C19.7317 30.2243 19.641 30.2043 19.557 30.1668C19.4729 30.1293 19.3972 30.075 19.3347 30.0073C19.2163 29.8768 19.1509 29.7066 19.1509 29.5301C19.1509 29.3536 19.2163 29.1834 19.3347 29.0529L24.1996 24.1985C24.258 24.1203 24.3325 24.0558 24.4179 24.009C24.5034 23.9623 24.5982 23.9344 24.6953 23.9275C24.7924 23.9206 24.8894 23.9348 24.9806 23.9689C25.0718 24.0031 25.1546 24.0565 25.2234 24.1256C25.2923 24.1946 25.346 24.2777 25.38 24.3693C25.4141 24.4608 25.4281 24.5584 25.4212 24.6559C25.4143 24.7533 25.3862 24.8483 25.3396 24.934C25.293 25.0198 25.229 25.0945 25.1511 25.1531L20.2862 30.0073C20.2288 30.0743 20.1581 30.1284 20.0785 30.166C19.9989 30.2036 19.9117 30.2239 19.8237 30.2255Z"
      fill="#475467"
    />
    <path
      d="M25.6109 30.7135C24.982 30.7135 24.3669 30.5265 23.844 30.1759C23.3211 29.8253 22.9135 29.3269 22.6729 28.7439C22.4322 28.1609 22.3697 27.5192 22.4924 26.9003C22.6151 26.2813 22.9175 25.7128 23.3623 25.2665C23.807 24.8203 24.3737 24.5165 24.9905 24.3933C25.6074 24.2702 26.2468 24.3334 26.8278 24.5749C27.4089 24.8164 27.9057 25.2254 28.2551 25.7501C28.6045 26.2748 28.7905 26.8917 28.7905 27.5228C28.7834 28.3668 28.4464 29.1742 27.8516 29.7711C27.2568 30.3679 26.4521 30.7064 25.6109 30.7135ZM25.6109 25.6957C25.2508 25.6957 24.8985 25.8028 24.5991 26.0036C24.2996 26.2044 24.0661 26.4897 23.9282 26.8235C23.7904 27.1574 23.7545 27.5249 23.8247 27.8793C23.895 28.2338 24.0684 28.5593 24.323 28.8148C24.5777 29.0703 24.9027 29.2443 25.2559 29.3148C25.6092 29.3853 25.9749 29.3493 26.3076 29.211C26.6404 29.0727 26.925 28.8385 27.1251 28.538C27.3252 28.2375 27.4316 27.8842 27.4316 27.5228C27.4389 27.2783 27.3971 27.0347 27.3089 26.8067C27.2206 26.5787 27.0879 26.3708 26.9181 26.1953C26.7483 26.0199 26.5448 25.8804 26.3202 25.7852C26.0957 25.69 25.8547 25.641 25.6109 25.6411V25.6957Z"
      fill="#475467"
    />
    <path
      d="M5.00429 23.301C4.91227 23.2998 4.82164 23.2798 4.73755 23.2423C4.65346 23.2048 4.57784 23.1505 4.51527 23.0828L0.193749 18.6102C0.0692556 18.4828 0 18.3115 0 18.133C0 17.9545 0.0692556 17.7832 0.193749 17.6558C0.254749 17.5906 0.328918 17.5385 0.41072 17.503C0.492523 17.4674 0.580355 17.4492 0.669494 17.4492C0.758633 17.4492 0.847129 17.4674 0.928932 17.503C1.01073 17.5385 1.08424 17.5906 1.14524 17.6558L5.46676 22.1555C5.59126 22.2829 5.66051 22.4544 5.66051 22.6329C5.66051 22.8113 5.59126 22.9826 5.46676 23.1101C5.33856 23.2241 5.17532 23.2914 5.00429 23.301Z"
      fill="#475467"
    />
    <path
      d="M42.9173 23.2929C42.7452 23.2879 42.5811 23.22 42.4554 23.102C42.3905 23.0408 42.3389 22.9669 42.3035 22.8848C42.2681 22.8027 42.2498 22.7142 42.2498 22.6248C42.2498 22.5353 42.2681 22.4469 42.3035 22.3648C42.3389 22.2827 42.3905 22.2086 42.4554 22.1474L46.777 17.6477C46.8354 17.5696 46.9098 17.5049 46.9953 17.4581C47.0807 17.4114 47.1749 17.3836 47.272 17.3767C47.3691 17.3698 47.4667 17.3838 47.5579 17.418C47.6491 17.4522 47.732 17.5056 47.8008 17.5746C47.8696 17.6437 47.9227 17.7268 47.9567 17.8183C47.9908 17.9098 48.0048 18.0077 47.9979 18.1051C47.991 18.2025 47.9635 18.2973 47.9169 18.3831C47.8703 18.4689 47.8057 18.5435 47.7278 18.6021L43.4341 23.0747C43.2975 23.2131 43.1114 23.2916 42.9173 23.2929Z"
      fill="#475467"
    />
    <path
      d="M10.8538 19.7279C10.7487 19.7259 10.6453 19.7003 10.5512 19.6532C10.4572 19.606 10.3744 19.5382 10.3097 19.4551L6.72271 14.5462C6.65772 14.485 6.60552 14.4111 6.5701 14.329C6.53469 14.2469 6.51636 14.1585 6.51636 14.069C6.51636 13.9796 6.53469 13.8911 6.5701 13.809C6.60552 13.7269 6.65772 13.653 6.72271 13.5918C6.79574 13.5395 6.8781 13.5022 6.96556 13.4821C7.05303 13.462 7.14386 13.4594 7.2323 13.4746C7.32074 13.4898 7.40532 13.5225 7.48112 13.5707C7.55692 13.6188 7.62205 13.6817 7.67354 13.7554L11.2612 18.637C11.3693 18.7816 11.4161 18.9635 11.3906 19.1425C11.3651 19.3215 11.2693 19.4831 11.1252 19.5916C11.0439 19.6537 10.9521 19.6999 10.8538 19.7279Z"
      fill="#475467"
    />
    <path
      d="M37.081 19.7226C36.9354 19.7145 36.7949 19.6675 36.6736 19.5863C36.5294 19.4778 36.4337 19.3162 36.4082 19.1372C36.3827 18.9582 36.4294 18.7763 36.5376 18.6317L40.1252 13.7501C40.1699 13.6625 40.2326 13.5856 40.3097 13.5247C40.3868 13.4639 40.4758 13.4207 40.5711 13.3977C40.6665 13.3748 40.7662 13.3728 40.8624 13.3919C40.9586 13.4111 41.0491 13.451 41.1285 13.5088C41.2079 13.5665 41.2742 13.6407 41.3222 13.7265C41.3703 13.8122 41.3992 13.9076 41.4072 14.0056C41.4151 14.1037 41.4017 14.2023 41.368 14.2948C41.3344 14.3872 41.2811 14.4711 41.2121 14.5409L37.6244 19.4498C37.5597 19.533 37.4776 19.6007 37.3836 19.6479C37.2895 19.695 37.1861 19.7206 37.081 19.7226Z"
      fill="#475467"
    />
    <path
      d="M17.049 17.271C16.902 17.271 16.7586 17.2232 16.6409 17.1347C16.5233 17.0462 16.4373 16.9218 16.3961 16.7802L14.9563 11.3259C14.9294 11.1595 14.9647 10.9889 15.0558 10.8473C15.1469 10.7057 15.2871 10.6029 15.4493 10.5588C15.6114 10.5148 15.7838 10.5326 15.9336 10.6088C16.0835 10.6849 16.2002 10.8141 16.2607 10.9713L17.7013 16.4256C17.7256 16.5116 17.7316 16.6017 17.7198 16.6903C17.7081 16.7789 17.6787 16.8643 17.6329 16.941C17.5871 17.0176 17.5262 17.0839 17.4538 17.136C17.3814 17.1881 17.2986 17.2248 17.2116 17.2437L17.049 17.271Z"
      fill="#475467"
    />
    <path
      d="M30.878 17.2665H30.7148C30.6278 17.2475 30.5457 17.2109 30.4733 17.1588C30.4009 17.1067 30.34 17.0402 30.2941 16.9636C30.2483 16.8869 30.2183 16.8017 30.2066 16.713C30.1948 16.6244 30.2015 16.5344 30.2258 16.4484L31.6663 10.9941C31.6883 10.9092 31.7272 10.8295 31.7804 10.7599C31.8337 10.6903 31.9001 10.6322 31.9762 10.5889C32.0522 10.5457 32.1362 10.5183 32.223 10.5082C32.3099 10.4982 32.3979 10.5058 32.4818 10.5305C32.5688 10.5495 32.6509 10.5861 32.7233 10.6382C32.7957 10.6903 32.8566 10.7566 32.9025 10.8333C32.9483 10.9099 32.9783 10.9952 32.99 11.0838C33.0018 11.1724 32.9951 11.2626 32.9708 11.3486L31.5303 16.8029C31.4843 16.9395 31.3967 17.0579 31.2795 17.1412C31.1623 17.2245 31.0216 17.2683 30.878 17.2665Z"
      fill="#475467"
    />
    <path
      d="M23.9472 16.2262C23.7717 16.2191 23.6063 16.1442 23.4847 16.0171C23.3631 15.89 23.2948 15.7206 23.2949 15.5444V9.81745C23.2749 9.71843 23.2774 9.61609 23.3016 9.518C23.3257 9.41991 23.3712 9.32858 23.4349 9.25034C23.4987 9.17211 23.5788 9.10904 23.6698 9.06575C23.7608 9.02246 23.8604 9 23.9611 9C24.0618 9 24.1614 9.02246 24.2524 9.06575C24.3434 9.10904 24.4236 9.17211 24.4873 9.25034C24.551 9.32858 24.5965 9.41991 24.6207 9.518C24.6448 9.61609 24.6467 9.71843 24.6266 9.81745V15.5444C24.6266 15.7252 24.555 15.8988 24.4276 16.0266C24.3001 16.1545 24.1274 16.2262 23.9472 16.2262Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiVisualDesign;
